import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import BrandAuth from '@uz/unitz-components-web/BrandAuth';
import FormRegister from '@uz/unitz-components-web/FormRegister';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-0 sm:my-11 md:mx-28 sm:wrapper app-row xl:mx-auto">
        <BrandAuth />
        <FormRegister />
      </div>
    </DIV>
  );
};

export default displayName(Index);
