import React from 'react';
import { Button, Row, Col, Alert, Space, Divider } from 'antd';

import { Link } from '@uz/unitz-components-web/Link';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';

import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Input, Form, FormItem } from 'formik-antd';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

const CustomInput = styled(FormItem)`
  .ant-input-affix-wrapper,
  .ant-input {
    border-radius: 8px !important;
    border-color: ${gstyles.colors.border} !important;
    &::-webkit-input-placeholder {
      color: ${gstyles.colors.sub};
    }
    &::-ms-input-placeholder {
      color: ${gstyles.colors.sub};
    }
    &::placeholder {
      color: ${gstyles.colors.sub};
    }
  }
`;

const CustomButton = styled.div`
  .ant-btn {
    border: 1px solid ${gstyles.colors.divider};
  }
`;

export default () => (
  <DIV className="formContainer">
    <div className="bg-white500 rounded-lg shadow-md p-4 sm:p-6">
      <h3 className="mb-6 text-2xl font-semibold text-center text-brandb500">
        {ctx.apply('i18n.t', 'Signup.signupLabel')}
      </h3>
      <DIV className="oauthRegisterForm">
        <Row>
          {/* <Col className="mb-4 formStatus" span={24}>
            <CustomButton>
              <Button
                className="relative"
                block
                onClick={() => ctx.get('authModel.facebookLogin')(ctx.get('handlers.onLoginRedirect'))}
                loading={ctx.get('authModel.signInFacebook_')}
                disabled={ctx.get('authModel.signInFacebook_')}
              >
                <div className="absolute w-6 transform -translate-x-1/2 -translate-y-1/2 left-8 top-1/2">
                  {gstyles.icons({ name: 'facebook2', size: 24 })}
                </div>
                <h5 className="mb-0 text-base font-semibold">{ctx.apply('i18n.t', 'Login.facebookLogin')}</h5>
              </Button>
            </CustomButton>
          </Col> */}
          <Col className="mb-4 formStatus" span={24}>
            <CustomButton>
              <Button
                className="relative"
                type="secondary"
                onClick={() => ctx.get('authModel.googleLogin')(ctx.get('handlers.onLoginRedirect'))}
                loading={ctx.get('authModel.signInGoogle_')}
                disabled={ctx.get('authModel.signInGoogle_')}
                block
              >
                <div className="absolute w-6 transform -translate-x-1/2 -translate-y-1/2 left-8 top-1/2">
                  {gstyles.icons({ name: 'google2', size: 24 })}
                </div>
                <h5 className="mb-0 inline-block text-base font-semibold">
                  {ctx.apply('i18n.t', 'Login.googleLogin')}
                </h5>
              </Button>
            </CustomButton>
          </Col>
          {/* <Col className="mb-4 formStatus" span={24}>
            <CustomButton>
              <Button
                className="relative"
                block
                onClick={() => ctx.get('authModel.appleLogin')(ctx.get('handlers.onPrevScreen'))}
                loading={ctx.get('authModel.signInApple_')}
                disabled={ctx.get('authModel.signInApple_')}
              >
                <div className="absolute w-6 transform -translate-x-1/2 -translate-y-1/2 left-8 top-1/2">
                  {gstyles.icons({ name: 'apple2', size: 24 })}
                </div>
                <h5 className="mb-0 text-base font-semibold">{ctx.apply('i18n.t', 'Login.appleLogin')}</h5>
              </Button>
            </CustomButton>
          </Col> */}
        </Row>
        <Divider plain>
          <div className="mb-0 text-sm font-normal uppercase">{ctx.apply('i18n.t', 'Signup.or')}</div>
        </Divider>
      </DIV>

      <FormProvider form={ctx.get('form')}>
        <Form {...layout} className="login-form" id="login-form">
          <Row>
            <Col className="formStatus" span={24}>
              {!!ctx.get('form.status.error') && (
                <div className="py-2">
                  <Alert message={ctx.get('form.status.error.message')} type="error" />
                </div>
              )}
              {!!ctx.get('form.status.success') && (
                <div className="py-2">
                  <Alert message={ctx.get('form.status.success.message')} type="success" />
                </div>
              )}
            </Col>
          </Row>
          <DIV className="oauthRegisterForm">
            <CustomInput name="username">
              <Input
                className="rounded-lg"
                name="username"
                placeholder={ctx.apply('i18n.t', 'Signup.email')}
                size="large"
              />
            </CustomInput>
            <Row>
              <Col span={24}>
                <Button
                  className="rounded-lg shadow-sm"
                  size="large"
                  type="primary"
                  onClick={ctx.get('registerEmail.toggleEmailRegister')}
                  disabled={!ctx.get('canRegisterEmail')}
                  loading={ctx.get('form.isSubmitting')}
                  block
                >
                  <span id="button-text">{ctx.apply('i18n.t', 'Signup.nextStep')}</span>
                </Button>
              </Col>
            </Row>
          </DIV>

          <DIV className="emailRegisterForm">
            <CustomInput name="username">
              <Input
                className="rounded-lg"
                name="username"
                placeholder={ctx.apply('i18n.t', 'Signup.email')}
                size="large"
              />
            </CustomInput>
            <CustomInput name="name">
              <Input
                className="rounded-lg"
                name="name"
                placeholder={ctx.apply('i18n.t', 'Signup.fullname')}
                size="large"
              />
            </CustomInput>
            <CustomInput name="password">
              <Input.Password
                className="rounded-lg"
                name="password"
                placeholder={ctx.apply('i18n.t', 'Signup.password')}
                size="large"
              />
            </CustomInput>
            <CustomInput name="passwordConfirm">
              <Input.Password
                className="rounded-lg"
                name="passwordConfirm"
                placeholder={ctx.apply('i18n.t', 'Signup.passwordConfirm')}
                size="large"
              />
            </CustomInput>
            <Row className="mt-6 mb-8">
              <Col span={24}>
                <Button
                  className="rounded-lg shadow-sm bg-brandb500"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  id="submit"
                  disabled={!ctx.get('canSubmit')}
                  loading={ctx.get('form.isSubmitting')}
                  block
                >
                  <span>{ctx.apply('i18n.t', 'Signup.signup')}</span>
                </Button>
              </Col>
            </Row>
            <div className="relative cursor-pointer">
              <button
                className="absolute text-white500 -left-2 top-1"
                onClick={ctx.get('registerEmail.toggleEmailRegister')}
              >
                {gstyles.icons({
                  name: 'arrow-left2',
                  size: 18,
                  fill: gstyles.colors.brandb500,
                })}
              </button>
            </div>
          </DIV>
        </Form>
        <div className="mt-3 text-center">
          <Space>
            <div className="font-normal text-main mb-0 text-base">{ctx.apply('i18n.t', 'Signup.loginQuestion')}</div>
            <Link to={ctx.apply('routeStore.toLocale', '/login')}>
              <h5 className="mb-0 font-medium text-base text-brandb500">
                {ctx.apply('i18n.t', 'Signup.loginRedirect')}
              </h5>
            </Link>
          </Space>
        </div>
      </FormProvider>
    </div>
  </DIV>
);
